import s from './App.module.css';
import Registration from './pages/Registration/Registration';
import { Route, Routes } from 'react-router-dom';
import Welcome from './pages/Welcome/Welcome';
import ThankYou from './pages/ThankYou/ThankYou';
import ConfirmAge from './pages/ConfirmAge/ConfirmAge';
import WaitYou from './pages/WaitYou/WaitYou';

function App() {
  return (
    <div className={s.app}>
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/confirm" element={<ConfirmAge />} />
        <Route path="/waityou" element={<WaitYou />} />
      </Routes>
    </div>
  );
}

export default App;
