import React from 'react';
import s from './WaitYou.module.css';
import Header from '../../components/Header/Header';
import img from '../../images/hands.png';

export default function WaitYou() {
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <img src={img} alt="" className={s.img} />
        <h2 className={s.title}>Ждем вас в Q CLUB</h2>
        <p className={s.text}>
          Программа лояльности, которая открывает доступ к специальным
          предложениям, заданиям, комплиментам и сервисам — с заботой о вас!
        </p>
        <button className={s.button}>Вступить в Q CLUB</button>
      </div>
    </div>
  );
}
