import React from 'react';
import s from './Checkbox.module.css';

export default function Checkbox({ children, value, setValue }) {
  const toggleValue = () => {
    setValue(!value);
  };

  return (
    <div className={s.container}>
      <button
        className={`${s.label} ${value ? s.active : ''}`}
        onClick={toggleValue}
      ></button>
      {children}
    </div>
  );
}
