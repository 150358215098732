import React from 'react';
import s from './ConfirmAge.module.css';
import Header from '../../components/Header/Header';

export default function ConfirmAge() {
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>Добро пожаловать!</h2>
        <p className={s.text}>
          Для того, чтобы получить вознаграждение*, необходимо подтвердить свой
          возраст по кнопке ниже, или в фирменных магазинах Q STORE и
          магазинах-партнерах Q PARTNER
        </p>
        <button className={s.button}>Подтвердить возраст</button>
      </div>
      <p className={s.bottomText}>Партнер</p>
      <p className={s.bottomText}>
        * Вознаграждение доступно за первичную успешную регистрацию, после
        прохождения процедуры подтверждения возраста.
      </p>
    </div>
  );
}
