import React, { useState } from 'react';
import s from './Registration.module.css';
import Header from '../../components/Header/Header';
import Checkbox from '../../components/Checkbox/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import arrow from '../../images/arrow.svg';

export default function Registration() {
  const [communicationAgree, setCommunicationAgree] = useState(false);
  const [infoAgree, setInfoAgree] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>
          Зарегистрироваться в базе данных совершеннолетних потребителей
          табачной или никотинсодержащей продукции и получить вознаграждение*
        </h2>
        <form action="" className={s.form}>
          <input type="text" placeholder="Имя*" className={s.input} />
          <input type="text" placeholder="Фамилия*" className={s.input} />
          <input type="text" placeholder="Отчество" className={s.input} />
          <input type="text" placeholder="Дата рождения*" className={s.input} />
          <input type="text" placeholder="Email*" className={s.input} />
          <input type="text" placeholder="Город" className={s.input} />
        </form>

        <Checkbox
          children={
            <p className={s.text}>
              Я подтверждаю, что согласен на получение коммуникации от ООО
              “ФМСМ”
            </p>
          }
          value={communicationAgree}
          setValue={setCommunicationAgree}
        />
        <Checkbox
          children={
            <p className={s.text}>
              Я подтверждаю, что согласен с{' '}
              <Link to="/" className={s.span}>
                Условиями соглашения о предоставлении информации, получении и
                обработке персональных данных
              </Link>
            </p>
          }
          value={infoAgree}
          setValue={setInfoAgree}
        />
        <button
          className={s.button}
          disabled={!communicationAgree || !infoAgree}
          onClick={() => {
            navigate('/welcome');
          }}
        >
          Подтвердить
          <img src={arrow} alt="arrow" />
        </button>
      </div>
      <p className={s.bottomText}>*обязательное поле для регистрации</p>
    </div>
  );
}
