import React from 'react';
import s from './ThankYou.module.css';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';

export default function ThankYou() {
  const navigate = useNavigate();
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>Спасибо!</h2>
        <p className={s.text}>
          Ваше вознаграждение будет направленно вам в течение 10 (десяти) дней.
        </p>
        <button
          className={s.button}
          onClick={() => {
            navigate('/waityou');
          }}
        >
          Далее
        </button>
      </div>
    </div>
  );
}
