import React from 'react';
import s from './Welcome.module.css';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';

export default function Welcome() {
  const navigate = useNavigate();
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>Добро пожаловать!</h2>
        <p className={s.text}>Выбрать вознаграждение*:</p>
        <button
          className={s.button}
          onClick={() => {
            navigate('/thankyou');
          }}
        >
          200 баллов OZON
        </button>
        <button
          className={s.button}
          onClick={() => {
            navigate('/confirm');
          }}
        >
          200₽ на номер мобильного телефона
        </button>
        <p className={s.bottomText}>
          * Вознаграждение доступно за первичную успешную регистрацию, после
          прохождения процедуры подтверждения возраста.
        </p>
      </div>
    </div>
  );
}
