import React from 'react';
import s from './Header.module.css';
import eighteenPlus from '../../images/18+.svg';

export default function Header() {
  return (
    <div className={s.header}>
      <img src={eighteenPlus} alt="18+" />
      <p className={s.text}>
        этот продукт не исключает риски для здоровья и вызывает зависимость
      </p>
    </div>
  );
}
